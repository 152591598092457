import React from 'react'
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { RiMapPin2Fill } from "react-icons/ri";
import { FaPhone } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";

const Footer = () => {
  return (
    <div className="text-white py-8 flex justify-between">
      <div className="max-w-full mx-auto flex flex-col md:flex-row justify-between">
        {/* Section 1 */}
        <div className="mb-6 md:mb-0 md:w-1/2 lg:w-1/3">
          <h1 className="font-bold uppercase text-[16px]">Développeur Full Stack</h1>
          <p className="mt-4 text-gray-400">
          En tant que développeur Full Stack, je me spécialise dans la réalisation de projets variés, couvrant aussi bien les sites web que les applications web et mobiles.<br/><br/>

N’hésitez pas à me contacter pour échanger sur votre projet et le concrétiser !
          </p>
        </div>
        {/* Section 2 */}
        <div className="flex flex-col sml:items-center lg:w-1/3 sml:mt-0 mt-12 items-start">
        <div className='w-1/2'>
          <h1 className="font-bold uppercase text-[16px]">Osman Duri</h1>
            <div className="flex items-center gap-4 mt-4">
              <RiMapPin2Fill size={18} />
              <p className="text-[16px] text-gray-400">Garges les Gonesse</p>
            </div>
            <div className="flex items-center gap-4 mt-4">
              <FaPhone size={18} />
              <a href="tel:0764427855" className="text-[16px] text-gray-400">0764427855</a>
            </div>
            <div className="flex items-center gap-4 mt-4">
              <IoMdMail size={18} />
              <a href="mailto:o.duri@hotmail.com" className="text-[16px] text-gray-400">o.duri@hotmail.com</a>
            </div>
        </div>

        </div>
        {/* Section 3 */}
        <div className="flex flex-col sml:items-center lg:w-1/3 sml:mt-0 mt-12 items-start">
          <h1 className="font-bold uppercase text-[16px] mb-4">Suivez-moi</h1>
          <div className="flex gap-6">
          <a
              href="https://www.linkedin.com/in/osman-duri-b44430135/"
              target="_blank"
              rel="noopener noreferrer"
              className="bannerIcon"
              aria-label="LinkedIn"
            >
              

              <span className="bannerIcon reactBanner" aria-label="React">
              <FaLinkedinIn />
            </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
