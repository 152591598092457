import React from 'react'
import { bannerImg } from "../../assets/index";
import osmanImg from '../../assets/images/osman.jpeg'

const RightBanner = () => {
  return (
    <div className="w-full lgl:w-1/2 flex justify-center items-center relative">
      <img
        className="w-[200px] h-[250px] lgl:w-[450px] lgl:h-[450px] z-10"
        src={osmanImg}
        alt="bannerImg"
      />
      <div className="absolute bottom-0 w-[200px] h-[250px] lgl:w-[450px] lgl:h-[450px] bg-gradient-to-r from-[#1e2024] to-[#202327] shadow-shadowOne flex justify-center items-center"></div>
    </div>
  );
}

export default RightBanner