import React, { useState } from 'react';
import { IoMdClose } from "react-icons/io";
import { IoMdCheckbox } from "react-icons/io";
import { GrFormSchedule } from "react-icons/gr";
import imgAryas from '../../assets/images/projects/aryas_home/3.PNG'

export default function SideBarProject({ selectedProject, handleCloseSidebar }) {
    const [colorClose, setColorClose] = useState('black');

    return (
        <div className="overflow-y-auto max-h-[650px]">
            <div className='flex justify-between items-center'>
                <button 
                    onClick={handleCloseSidebar} 
                    className="text-right text-gray-700 w-[53px] h-[33px] flex justify-center items-center border border-black hover:bg-[black] hover:border-none" 
                    onMouseEnter={() => setColorClose("white")} 
                    onMouseLeave={() => setColorClose('black')}
                >
                    <IoMdClose size={20} color={colorClose}/>
                </button>
                <h2 className="text-2xl font-bold text-black sm:text-lg uppercase">{selectedProject.name}</h2>
            </div>
            <div className='w-full h-[1px] bg-black mt-8 opacity-10'></div>
            <div className='flex justify-center items-center'>
                <img 
                    src={selectedProject.src} 
                    alt={`${selectedProject.name} image`} 
                    className='h-[400px] w-full border border-black border-[4px]'
                />
            </div>
            <div className='mt-4 flex justify-between gap-4'>
                <div className='w-1/2'>
                    <h1 className='text-black uppercase text-[21px] font-bold sm:text-sm'>DÉVELOPPEMENT</h1>
                    <p className='w-[270px] text-[#7a7a7a] text-[11px] sml:text-[14px] mt-1 mb-2  sm:w-[100%]'>{selectedProject.des}</p>
                    <div className='bg-black w-full h-[1px]'></div>
                    <div className="mt-2">
                        {
                        selectedProject.developpement.map((e, index) => (
                            <p key={index} className="flex items-start gap-1 sml:gap-4 text-black text-[11px] sml:text-[13px] sm:gap-1 mt-0 sml:mt-0">
                            <input type="checkbox" id="scales" name="scales" checked className="accent-black transform scale-75"/>
                            {e}
                            </p>
                        ))}
                    </div>

                </div>
                <div className='w-1/2'>
                    <h1 className='text-black uppercase text-[21px] font-bold mb-2 sm:text-sm'>INFORMATION</h1>
                    <div className='bg-black w-full h-[1px]'></div>
                    <div className='flex flex-col items-start py-3'>
                        <p className='flex items-center text-[#7a7a7a] text-[11px] sml:text-[14px]'>
                            <GrFormSchedule size={20}/> Année : <p className='ml-1 font-bold text-[11px] font-bold sml:text-[14px]'> {selectedProject.annee}</p>
                        </p>
                        <p className='flex items-center text-[#7a7a7a] text-[11px] sml:text-[14px]'>
                            <GrFormSchedule size={20}/> Catégorie : <p className='ml-1 text-[11px] font-bold sml:text-[14px]'> {selectedProject.categorie}</p>
                        </p>
                    </div>
                    <div className='bg-black w-full h-[1px]'></div>
                    <div className='mt-2 flex flex-wrap'>
                        {
                            selectedProject.information.map((e, index) => (
                                <p key={index} className='text-[11px] mt-1 mr-1 mb-1 ml-0 text-white bg-[#212428] p-2 sml:text-[14px]'>
                                    {e}
                                </p>
                            ))
                        }
                    </div>
                    
                    <div className='bg-black w-full h-[1px] mt-2'></div>
                    <a href={selectedProject.site} target="_blank"className='border border-black mt-2 w-1/2 flex justify-center items-center py-1 text-black cursor-pointer hover:bg-black hover:text-white sm:text-xs'>
                        Visiter le site
                    </a>
                </div>
            </div>
            <div className=''>
            <h1 className='text-black uppercase text-[21px] font-bold sm:text-sm mt-4'>détails:</h1>
            <p className='text-[#7a7a7a] text-xs sml:text-sm w-[95%]'>{selectedProject.details}</p>
            </div>
        </div>
    )
}
