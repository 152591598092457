import React from 'react';
import { motion, useInView } from 'framer-motion';
import features from '../../data/features_data';

import Title from '../layouts/Title';
import Card from './Card';

const InViewFeature = ({ feature, index }) => {
  const ref = React.useRef(null);
  const isInView = useInView(ref, { triggerOnce: true, threshold: 0.1 });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      style={{ cursor: 'pointer' }}  // Utilisation du style inline pour cursor-pointer
    >
      <Card
        title={feature.title}
        des={feature.des}
        icon={feature.icon}
      />
    </motion.div>
  );
};

const Features = () => {
  return (
    <section
      id="features"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <Title title="" des="Services" />
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-20">
        {features.map((feature, index) => (
          <InViewFeature feature={feature} index={index} key={index} />
        ))}
      </div>
    </section>
  );
}

export default Features;
