import React, {  useState } from 'react'
import Title from '../layouts/Title';
import Education from './Education';
import Skills from './Skills';
import Achievement from './Achievement';
import Experience from "./Experience"

const Resume = () => {
   const [educationData, setEducationData] = useState(false);
   const [skillData, setSkillData] = useState(true);
   const [experienceData, setExperienceData] = useState(false);
   const [achievementData, setAchievementData] = useState(false);
  return (
    <section id="resume" className="w-full py-20 border-b-[1px] border-b-black">
      <div className="flex justify-center items-center text-center">
        <Title title="" des="Compétences" />
      </div>
      <div>

      </div>
      {/*educationData && <Education />*/}
      {skillData && <Skills />}
      {/*achievementData && <Achievement />*/}
      {/*experienceData && <Experience />*/}
 
    </section>
  );
}

export default Resume