import React, { useState, useRef, useEffect } from 'react';
import { motion, useInView, AnimatePresence } from 'framer-motion';
import Title from '../layouts/Title';
import ProjectsCard from './ProjectsCard';
import projects from '../../data/projectsData';
import SideBarProject from './SideBarProject';  // Assurez-vous que le chemin est correct

const Projects = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const sidebarRef = useRef(null);
  const sortedProjects = [...projects].sort((a, b) => a.id - b.id);

  const handleCardClick = (project) => {
    setSelectedProject(project);
  };

  const handleCloseSidebar = () => {
    setSelectedProject(null);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      handleCloseSidebar();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <section
      id="projects"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title
          title=""
          des="Mes Projets"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
        {sortedProjects.map((project, index) => (
          <InViewCard 
            project={project} 
            index={index} 
            key={index} 
            onClick={() => handleCardClick(project)} 
          />
        ))}
      </div>

      <AnimatePresence>
        {selectedProject && (
          <motion.div
            ref={sidebarRef}
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}  // Animation pour la fermeture
            transition={{ type: 'tween', ease: 'easeInOut', duration: 0.5 }}  // Transition douce
            className={`fixed top-0 right-0 h-full bg-white shadow-lg z-50 p-4 overflow-y-auto 
                       w-full sm:w-[100%] md:w-[80%] mdl:w-[60%] lg:w-[35%] xl:w-1/3`} // Responsiveness with Tailwind classes
          >
            <SideBarProject selectedProject={selectedProject} handleCloseSidebar={handleCloseSidebar} />
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  );
};

const InViewCard = ({ project, index, onClick }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { triggerOnce: true, threshold: 0.1 });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      onClick={onClick}
      className='cursor-pointer'
    >
      <ProjectsCard
        title={project.title}
        des={project.des}
        src={project.src}
        name={project.name}
        github={project.github}
        site={project.site}
        details={project.details}
      />
    </motion.div>
  );
};

export default Projects;
