import React from 'react'
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { GrLinkedin } from "react-icons/gr";
import { contactImg } from "../../assets/index";

const ContactLeft = () => {
  return (
    <div className="w-full lgl:w-[35%] h-full bg-gradient-to-r from-[#1e2024] to-[#23272b] p-4 lgl:p-8 rounded-lg shadow-shadowOne flex flex-col gap-8 justify-center">
      <img
        className="w-full h-64 object-cover rounded-lg mb-2"
        src={contactImg}
        alt="contactImg"
      />
      <div className="flex flex-col gap-4">
        <h3 className="text-3xl font-bold text-white">Osman Duri</h3>
        <p className="text-lg font-normal text-gray-400">
          Développeur Full-Stack
        </p>
        <p className="text-base text-gray-400 tracking-wide">
        Vous pouvez me contacter à tout moment pour échanger et discuter de vos projets professionnels.
        </p>
        <p className="text-base text-gray-400 flex items-center gap-2">
          Phone: <span className="text-lightText">+33764427855</span>
        </p>
        <p className="text-base text-gray-400 flex items-center gap-2">
          Email: <span className="text-lightText">o.duri@hotmail.com</span>
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <h2 className="text-base uppercase font-titleFont mb-4">RESEAUX SOCIAUX</h2>
        <div className="flex gap-4">
        <a
              href="https://www.linkedin.com/in/osman-duri-b44430135/"
              target="_blank"
              rel="noopener noreferrer"
              className="bannerIcon"
              aria-label="LinkedIn"
            >
              

              <span className="bannerIcon reactBanner" aria-label="React">
              <GrLinkedin />
            </span>
            </a>
        </div>
      </div>
    </div>
  );
}

export default ContactLeft