export const navLinksdata = [
  {
    _id: 1001,
    title: "Accueil",
    link: "home",
  },
  {
    _id: 1002,
    title: "Services",
    link: "features",
  },
  {
    _id: 1003,
    title: "Projets",
    link: "projects",
  },
  {
    _id: 1004,
    title: "Compétences",
    link: "resume",
  },
  /*{
    _id: 1005,
    title: "Testimonial",
    link: "testimonial",
  },*/
  {
    _id: 1006,
    title: "Contact",
    link: "contact",
  },
];