import React from 'react';
import { BsGithub } from "react-icons/bs";
import { FaGlobe } from "react-icons/fa";

const ProjectsCard = ({ title, des, src, name, github, site }) => {  
  
  return (
    <div className="w-full p-4 xl:px-12 h-[470px] xl:py-10 rounded-lg shadow-shadowOne flex flex-col bg-gradient-to-r from-bodyColor to-[#202327] group hover:bg-gradient-to-b hover:from-gray-900 hover:gray-900 transition-colors duration-1000">
      <div className="w-full h-[250px] overflow-hidden rounded-lg">
        <img
          className="w-full h-60 object-fill group-hover:scale-110 duration-300 cursor-pointer"
          src={src}
          alt={`${title} project`}
        />
      </div>
      <div className="w-full mt-5 flex flex-col gap-6">
        <div>
          <div className="flex items-center justify-between">
            <div className='flex flex-col h-[48px]'>
              <h3 className="text-base uppercase text-designColor font-normal">{title}</h3>
              <p className='uppercase'>{name}</p>
            </div>

            <div className="flex gap-2">
              {/*<a
                href={github}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="GitHub link"
                title="GitHub"
                className="text-lg w-10 h-10 rounded-full bg-black inline-flex justify-center items-center text-gray-400 hover:text-designColor duration-300 cursor-pointer"
                onClick={(e) => e.stopPropagation()}  // Stopper la propagation de l'événement de clic
              >
                <BsGithub />
              </a>*/}
              <a
                href={site}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Live site link"
                title="Live Site"
                className="text-lg w-10 h-10 rounded-full bg-black inline-flex justify-center items-center text-gray-400 hover:text-designColor duration-300 cursor-pointer"
                onClick={(e) => e.stopPropagation()}  // Stopper la propagation de l'événement de clic
              >
                <FaGlobe />
              </a>
            </div>
          </div>
          <p className="text-sm tracking-wide mt-3 hover:text-gray-100 duration-300">
            {des}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ProjectsCard;
