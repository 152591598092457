import React from 'react'
import { IoMdCheckbox } from "react-icons/io";
export default function SkillsDetails({title, text}) {
  return (
    <div className='className="mt-14 w-full flex flex-col gap-0 mb-7'>
        <div className='flex items-center gap-1'>
            <IoMdCheckbox color="white"/>
            <h1 className='text-[14px] font-bold'>{title}</h1>
        </div>
            <p className='text-[14px]'>{text}</p>
    </div>
  )
}
