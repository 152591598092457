import { projectOne, projectTwo, projectThree, projectFour, projectFive, projectSix, projectSeven } from "../assets/index";

const projects = [
    {
      id:3,
      title: "SITE E-COMMERCE",
      des: "Développement d'un site e-commerce pour Arya's Home, une marque française experte dans la vente d'ameublement.",
      src: projectOne,
      name: "Aryas's Home",
      annee:"2020",
      categorie:"E-commerce",
      github:"https://github.com/osmanduri/aryass_home",
      site:"https://aryas-home.fr/",
      details:"Le site e-commerce d'Arya's Home, une marque française spécialisée dans la vente de mobiliers, propose une sélection de lits coffres, cadres de lit, ensembles sommiers-matelas, ainsi que des canapés de luxe, avec la possibilité de paiements en plusieurs fois.",
      developpement:[
        "Rédaction du cahier des charges.",
        "Création de maquettes.",
        "Création de la BDD avec MongoDb",
        "Développement du Backend et du Frontend",
        "Établissement des standards de sécurité",
        "Testing du back-end et front-end (Jest)",
        "Création d'un back office",
        "Paiement sécurisé via Stripe et PayPal.",
        "Utilisation de webhooks pour les paiements Stripe.",
        "Téléchargement des factures pour les clients",
        "Ajout de codes promo",
        "Mise en prod sur un VPS (Nginx)",
        

    ],
    information:[
        "NodeJS (ExpressJS)",
        "ReactJS",
        "Typescript",
        "Tailwind",
        "Redux",
        "MongoDB",
        "Nginx",
        "Stripe",
        "Handlebars"
    ]
    },
    {
      id:4,
      title: "APPLICATION VTC",
      des: "Développement d'une application de VTC pour Elegance Drive Paris, une entreprise de transport.",
      src: projectTwo,
      name: "Elegance Drive Paris",
      annee:"2019",
      categorie:"Application VTC",
      github:"https://github.com/osmanduri/EleganceDriveParisVTC",
      site:"https://elegance-drive-paris.com/",
      details:"L'application établit un itinéraire visible sur une carte Google, génère automatiquement des tarifs en fonction de la distance parcourue et de la durée estimée du trajet, puis propose une gamme de prix variés en fonction de la catégorie de véhicule choisie (ex : CONFORT, LUXE, et VAN).",
      developpement:[
        "Intégration des maquettes",
        "React / Node Typescript",
        "Développement du Backend et du Frontend",
        "Gestion du responsive.",
        "Optimisation SEO",
        "Paiement sécurisé via Stripe.",
        "Utilisation de l'API de Google Maps.",
        "Site web disponible en plusieurs langues.",
        "Envoi des factures par email.",
        "Factures et paiements stockés dans MongoDB"
    ],
    information:[
        "NodeJS (ExpressJS)",
        "ReactJS",
        "Typescript",
        "Tailwind",
        "SCSS",
        "SEO",
        "Handlebars",
        "Google Maps API",
        "Stripe"
    ]
      
    },
    {
      id:5,
      title: "SAAS GESTION DE CLIENTS",
      des: "Développement d'une application SaaS pour la gestion des clients et des programmes de fidélité d'un salon de hammam.",
      src: projectThree,
      name: "Les Bains D'Aulnay",
      annee:"2018",
      categorie:"SAAS",
      github:"https://github.com/osmanduri/les_bains_project",
      site:"https://les-bains-aulnay-app.netlify.app/",
      details:"Développement d'une application personnalisée pour la gestion des entrées et des programmes de fidélité des clients via leur numéro de téléphone. L'application comprend un programme de fidélité intégré qui récompense les clients en fonction de leur fréquence de visites, avec des limitations de 12 heures entre les entrées. Un algorithme MongoDB automatise l'envoi de SMS de fidélisation 15 jours avant l'anniversaire des clients. La gestion des accès est sécurisée avec des routes dédiées pour les comptes administrateurs et clients. Les administrateurs peuvent ajouter, modifier, ou supprimer des informations clients, y compris une carte de visite virtuelle, et un historique détaillé permet de suivre chaque client.",
      developpement:[
        "Développement du Backend et du Frontend",
        "Creation des comptes Admin et Client",
        "Gestion des droits d'accès pour les comptes Admin et Client.",
        "Envoi d'SMS avec l'API de Twilio",
        "Gestion du responsive.",
        "Utilisation d'agrégations dans MongoDB.",
        "Création d'une carte virtuelle pour chaque client, affichant le nombre de points de fidélité.",
        "Suivi de l'historique des interactions des clients.",
    ],
    information:[
        "NodeJS (ExpressJS)",
        "ReactJS",
        "Typescript",
        "Tailwind",
        "API Twilio",
        "Handlebars"
    ]
    },
    {
      id:1,
      title: "SITE WEB / APPLICATION",
      des: "Développement d'une application de test d'éligibilité à la fibre optique ainsi que le site web from scratch.",
      src: projectFour,
      name: "INTEGRAPHONE",
      annee:"2024",
      categorie:"Test d'eligibilite",
      github:"https://github.com/osmanduri/IntegraphoneEligibiliteFibre",
      site:"https://integraphone.fr/",
      details:"Développement du site internet d'Integraphone, incluant une application de test d'éligibilité à la fibre optique, comparable à celles d'opérateurs comme Orange ou SFR. Conception du back-end et gestion de la base de données avec MongoDB, ainsi que la création d'une API pour la gestion du site, des factures, et des contrats. Mise en place d'un système de gestion des rôles utilisateurs (Normal, Admin, SuperAdmin) et développement d'interfaces connectées aux API REST. Réalisation de tests unitaires avec JEST pour vérifier certaines fonctionnalités.",
      developpement:[
        "Développement du site from scratch",
        "Développement du Backend et du Frontend",
        "Développement d'une application d'éligibilité à la fibre.",
        "Gestion du responsive.",
        "Gestion du design avec SCSS",
        "Intégration à partir de maquettes sur Figma.",
        "Gestion des utilisateurs",
        "Gestion des Factures et Contrat",
        "Gestion des comptes user et admin",
        "Réalisation de test unitaire",
        "Gestion Factures ,Contrats ,User dans MongoDb"
    ],
    information:[
        "NodeJS (ExpressJS)",
        "ReactJS",
        "HTML",
        "SCSS",
        "SEO",
        "NODE-SASS"
    ]
    },
    {
      id:2,
      title: "SAAS HOTELERIE",
      des: "Développement d'un CRM facilitant la gestion et la relation entre les hôtels et les clients",
      src: projectFive,
      name: "HOTELAPPZ",
      annee:"2022",
      categorie:"SAAS",
      github:"",
      site:"https://app.guestappz.io/login",
      details:"Développement d'API REST en NodeJS et de composant réutilisable avec le framework React JS, incluant la conception de modèles de données avec Mongoose et la gestion de bases de données MongoDB. Participation à l'intégration de connecteurs API vers des systèmes de gestion de propriétés (PMS) tels que Sevenrooms, Mews, et Apaleo. Contribution aux tests et débogages des applications, avec la création de documentation API sur Postman et Insomnia, ainsi que la réalisation de templates sur Stripo.",
      developpement:[
        "Test unitaire avec Mocha",
        "Création de template avec Stripo",
        "Test des routes avec des scripts",
        "Connecteur PMS (Apaleo, Sevenrooms)",
        "Débogage des applications",
        "Réalisation de Data Model(mongoose)"

    ],
    information:[
        "NodeJS (ExpressJS)",
        "AngularJS",
        "HTML",
        "SCSS",
        "Stripo",
        "Handlebars"
    ]
    },
    {
      id:6,
      title: "SITE WEB",
      des: "Développement du site d'Infra Bâtiment, entreprise de construction et de génie civil offrant des services dans le domaine de la construction.",
      src: projectSix,
      name: "INFRA BATIMENT",
      annee:"2018",
      categorie:"Site web",
      github:"https://github.com/osmanduri/InfraBatimentProject",
      site:"https://infra-batiment.fr/",
      details:"Développement d'API REST en Node.js avec des opérations CRUD, conception des maquettes d'interfaces utilisateurs sur Figma et création des interfaces UI avec React JS. Intégration de nouvelles fonctionnalités, utilisation de React Context API pour l'intégration des APIs, et maintien de la qualité du code. Déploiement des applications sur une machine Linux.",
      developpement:[
        "Développement du Backend et Frontend",
        "Sécurisation des routes avec jwt",
        "Création de back office",
        "Création de la BD avec MongoDb",
        "Publication des chantiers",
        "Espace commentaire pour les posts",
        "Partage (Whatapps, Facebook, Linkedin)",
        "Calcul de nombre de vue",
        "Création de logo",
        "Action restreinte pour membre non connecté"
        

      ],
      information:[
        "NodeJS (ExpressJS)",
        "ReactJS",
        "HTML",
        "SCSS"
    ]
    },
    {
      id:7,
      title: "SITE WEB",
      des: "Développement du site Wolfcars proposant des services pour les véhicules, incluant l'entretien, la réparation, le montage de pneus etc...",
      src: projectSeven,
      name: "WOLFCARS",
      annee:"2017",
      categorie:"Site vitrine",
      github:"https://github.com/osmanduri/WolfCarsProject",
      site:"https://www.wolfcars95.com/",
      developpement:[
        "Développement du Frontend",
        "Publication des images",
        "Création de logo",    
        "Design avec SCSS"

      ],
      information:[
        "ReactJS",
        "HTML",
        "SCSS"
    ],
      details:"Conception de maquettes d'interfaces utilisateurs sur Figma, et création des interfaces UI avec React JS. Intégration de nouvelles fonctionnalités, maintien de la qualité du code. Déploiement des applications sur une machine Linux.",
    },
  ];
  
  export default projects;
  
