import { FaMobile, FaGlobe } from "react-icons/fa";
import { SiAntdesign } from "react-icons/si";
import { MdIntegrationInstructions } from "react-icons/md";
import { MdDashboardCustomize } from "react-icons/md";
import { AiOutlineFileSearch } from "react-icons/ai";
import { SiFastapi } from "react-icons/si";
import { MdAdminPanelSettings } from "react-icons/md";

const features = [
    {
      title: "Projets Web",
      des: `Site vitrine, E-commerce, Application mobile, SaaS, Intranet, CRM`,
      icon: <FaGlobe />,
    },
    {
      title: "Intégration Web",
      des: "Des intégrations (HTML / CSS), respectueuses des standards du Web.",
      icon: <MdIntegrationInstructions  />,
    },
    {
      title: "Sur Mesure",
      des: "Outils adaptés, applications & solutions personnalisées.",
      icon: <MdDashboardCustomize />,
    },
    {
      title: "Référencement Naturel",
      des: "Présentation claire des informations, avec des pages optimisées pour un meilleur référencement.",
      icon: <AiOutlineFileSearch />,
    },
    {
      title: "Conception Graphique",
      des: "Logos, templates web, newsletters…",
      icon: <SiAntdesign />,
    },
    {
      title: "Dynamisme Des Pages",
      des: "Des animations de contenu pour sublimer votre projet.",
      icon: <SiFastapi />,
    },
    {
      title: "Interface D'administration",
      des: "Des outils sur mesure pour assurer le bon fonctionnement de votre entreprise.",
      icon: <MdAdminPanelSettings />,
    },
    {
      title: "Responsive design",
      des: "Compatible avec tous les supports, y compris tablettes et applications mobiles.",
      icon: <FaMobile />,
    },
  ];

  export default features;

  