import React from 'react';
import { useTypewriter, Cursor } from "react-simple-typewriter";
import { FaLinkedinIn, FaReact, FaNodeJs } from "react-icons/fa";
import { SiTypescript, SiTailwindcss } from "react-icons/si";

const LeftBanner = () => {
  const [text] = useTypewriter({
    words: ["Développeur Full Stack", "React / Node / Typescript"],
    loop: true,
    typeSpeed: 20,
    deleteSpeed: 10,
    delaySpeed: 2000,
  });

  return (
    <div className="w-full lgl:w-1/2 flex flex-col gap-20">
      <div className="flex flex-col gap-5">
        <h1 className="text-6xl font-bold text-white">
          <span className="text-designColor capitalize">Osman Duri</span>
        </h1>
        <h2 className="text-4xl font-bold text-white">
          <span>{text}</span>
          <Cursor cursorBlinking={false} cursorStyle="|" cursorColor="#ff014f" />
        </h2>
        <p className="text-base font-bodyFont leading-6 tracking-wide">
          Passionné par le développement, je m'investis pleinement dans ce que je fais. La créativité et la rigueur sont des valeurs que je cultive sans relâche dans l'exercice de ma passion.
        </p>
      </div>
      <div className="flex flex-col xl:flex-row gap-6 lgl:gap-0 justify-between">
        <div>
          <h2 className="text-base uppercase font-titleFont mb-4">
            Réseaux sociaux :
          </h2>
          <div className="flex gap-4">
            <a
              href="https://www.linkedin.com/in/osman-duri-b44430135/"
              target="_blank"
              rel="noopener noreferrer"
              className="bannerIcon"
              aria-label="LinkedIn"
            >
              

              <span className="bannerIcon reactBanner" aria-label="React">
              <FaLinkedinIn />
            </span>
            </a>
          </div>
        </div>
        <div>
          <h2 className="text-base uppercase font-titleFont mb-4">
            Expertise en :
          </h2>
          <div className="flex gap-4">
            <span className="bannerIcon reactBanner" aria-label="React">
              <FaReact />
            </span>
            <span className="bannerIcon nodeBanner" aria-label="Node.js">
              <FaNodeJs />
            </span>
            <span className="bannerIcon typescriptBanner" aria-label="TypeScript">
              <SiTypescript />
            </span>
            <span className="bannerIcon tailwindBanner" aria-label="Tailwind CSS">
              <SiTailwindcss />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftBanner;
