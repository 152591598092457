import React, {useState, useEffect} from "react";
import Banner from "./components/banner/Banner";
import Contact from "./components/contact/Contact";
import Features from "./components/features/Features";
import Footer from "./components/footer/Footer";
import FooterBottom from "./components/footer/FooterBottom";
import Navbar from "./components/navbar/Navbar";
import Projects from "./components/projects/Projects";
import Resume from "./components/resume/Resume";
import logo_loading from './assets/images/logo_loading/logo_1.mp4'

const Preloader = ({ onFinished }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onFinished();
    }, 3000); // 3000 ms = 3 seconds

    return () => clearTimeout(timer);
  }, [onFinished]);

  return (
    <div style={styles.preloader}>
      <video 
      autoPlay 
      muted 
      style={styles.video}
      width="100%"
      height="100%"
      >
        
        <source src={logo_loading} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

function App() {
  const [loading, setLoading] = useState(true);
  return (
    <>
      {
        loading ? (
          <Preloader onFinished={() => setLoading(false)} />
        ) : (
          <div className="w-full h-auto bg-bodyColor text-lightText px-4">
          <Navbar />
        <div className="max-w-screen-xl mx-auto">
          <Banner />
          <Features />
          <Projects />
          <Resume />
          <Contact />
          <Footer />
          <FooterBottom />
        </div>
      </div>
        )
      }

    </>

  );
}

const styles = {
  preloader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    backgroundColor: '#fff',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 9999,
  },
  video: {
    width: '550px',
    height: '350px',
    objectFit: 'cover',
  },
};

export default App;
