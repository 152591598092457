

const resume_data = [
    {
        title:"PROJETS WEB",
        text:"Site web, Site e-commerce, CRM, Intranet, Saas, Application mobile"
    },
    {
        title:"LANGAGES DE PROGRAMMATION / FRAMEWORK/ BIBLIOTHÈQUES",
        text:"JavaScript : NodeJS / ReactJS / AngularJS, VueJS, PHP : Symfony / Laravel, C/C++/C#"
    },
    {
        title:"GESTION DE BASES DE DONNÉES ET INTERFACE D'ADMINISTRATION",
        text:"MySQL / PhpMyAdmin, MongoDB / MongoDB Compass"
    },
    {
        title:"INTÉGRATION (X)HTML / (S)CSS",
        text:"Code écrit à la manuellement dans le respect des normes W3C."
    },
    {
        title:"CONCEPTION MULTI-PLATEFORMES",
        text:"Compatible sur tous les supports : tablette et application mobile."
    }
  ];
  
  export default resume_data;
  
